'use client';

import { Menu, Transition } from '@headlessui/react';
import { classNames } from 'helpers/classNames';
import { forwardRef, Fragment, memo, RefObject, useEffect, useRef, useState } from 'react';

import { PLAYBACK_RATES } from 'services/playbackRates';

interface PlaybackControlsProps {
  isFullscreen: boolean;
}

const PlaybackControls = forwardRef(
  ({ isFullscreen }: PlaybackControlsProps, ref: RefObject<HTMLVideoElement>) => {
    const [playbackRate, setPlaybackRate] = useState(ref.current.playbackRate);

    useEffect(() => {
      const localRate = localStorage.getItem('playbackRate');
      if (localRate) {
        setPlayback(parseFloat(localRate));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setPlayback = async (r) => {
      ref.current.playbackRate = r;
      setPlaybackRate(r);
      localStorage.setItem('playbackRate', `${r}`);
    };

    return (
      <Menu as='div' className='relative inline-block text-left'>
        <Menu.Button className='w-14'>
          <div
            className={`block text-white font-semibold z-[2] relative h-full w-full bg-black cursor-pointer ${
              isFullscreen ? 'text-base' : 'text-13'
            }`}
            data-testid='playback-rate'>
            {`${playbackRate}x`}
          </div>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <Menu.Items
            data-testid='playback-rate-menu'
            className={classNames(
              isFullscreen ? 'bottom-7' : 'bottom-6',
              'absolute -right-3 z-10 mt-2 origin-top-right border border-solid rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
            )}>
            <div className='py-1'>
              {PLAYBACK_RATES.map((rate) => (
                <Menu.Item key={`${rate}`}>
                  <div
                    key={`${rate}`}
                    role='button'
                    className={classNames(
                      isFullscreen ? 'py-3 px-6' : 'py-2 px-3',
                      'cursor-pointer block border-b border-solid border-white/20 last:border-none text-white transition-colors duration-150 hover:text-teal-300'
                    )}
                    onClick={async () => await setPlayback(rate)}
                    data-testid={`playback-rate-item-${rate}`}>
                    <span className='inline-block font-medium text-sm'>{rate}</span>
                  </div>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
);

PlaybackControls.displayName = 'PlaybackControls';

export default memo(PlaybackControls);
