'use client';

import { Menu, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { PLAYBACK_RATES } from 'services/playbackRates';

import { AntIcon } from '../';

interface AudioPlayerProps {
  src: string;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(() => {
    const savedRate = localStorage.getItem('playbackRate');
    return savedRate ? parseFloat(savedRate) : 1;
  });
  const audioRef = useRef<HTMLAudioElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      const handleLoadedMetadata = () => {
        setDuration(audioElement.duration);
        setIsLoaded(true);
      };

      const handleError = () => {
        console.error('Error loading audio metadata');
        setIsLoaded(false);
      };

      const handleTimeUpdate = () => {
        if (audioElement.duration > 0) {
          const currentTime = audioElement.currentTime;
          setProgress((currentTime / audioElement.duration) * 100);
        }
      };

      audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      audioElement.addEventListener('error', handleError);
      audioElement.addEventListener('timeupdate', handleTimeUpdate);

      audioElement.src = src;
      audioElement.playbackRate = playbackRate;

      return () => {
        audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audioElement.removeEventListener('error', handleError);
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
      if (isPlaying) {
        audioRef.current.play();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackRate]);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (audioRef.current && duration > 0) {
      audioRef.current.currentTime = (value / 100) * duration;
      setProgress(value);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handlePlaybackRateChange = (rate: number) => {
    console.log('rate', rate);
    setPlaybackRate(rate);
    localStorage.setItem('playbackRate', `${rate}`);
    setMenuOpen(false);
  };

  return (
    <div className='flex items-start'>
      <audio ref={audioRef} src={src} />
      {isLoaded && (
        <div className='flex flex-col'>
          <div className='flex justify-between'>
            <div className='flex items-center'>
              <button onClick={togglePlay} className='px-1 mr-0.25 -ml-2 rounded hover-teal'>
                {isPlaying ? (
                  <AntIcon name='pause' color='#373e41' size={16} />
                ) : (
                  <AntIcon name='caretright' color='#373e41' size={16} />
                )}
              </button>
              <div className='flex justify-between items-center text-sm'>
                <span className='text-xs'>
                  {duration > 0 ? formatTime((duration * progress) / 100) : '0:00'}
                </span>
                <span className='text-xs'>/</span>
                <span className='text-xs'>{formatTime(duration)}</span>
              </div>
            </div>
            <Menu as='div' className='relative inline-block text-left' ref={menuRef}>
              <Menu.Button
                className='hover-teal rounded px-1 -mr-1'
                onClick={() => setMenuOpen(!menuOpen)}>
                <div className='block text-[#373e41] text-sm font-medium z-[2] relative h-full w-full cursor-pointer'>
                  {`${playbackRate}x`}
                </div>
              </Menu.Button>

              {menuOpen && (
                <>
                  <div
                    className='fixed inset-0 bg-black opacity-50 lg:opacity-0 z-10'
                    onClick={() => setMenuOpen(false)}
                  />
                  <Transition
                    as='div'
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    {isSmallScreen ? (
                      createPortal(
                        <Menu.Items className='z-[6] w-full fixed bottom-0  right-0 mt-2 origin-bottom-right  bg-white border border-gray-300 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <div className='py-1 shadow-xl'>
                            {PLAYBACK_RATES.map((rate) => (
                              <Menu.Item key={rate}>
                                {({ active }) => (
                                  <div
                                    role='button'
                                    className={`flex justify-center items-center cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-solid border-zinc-200 last:border-none transition-colors duration-150 hover:bg-teal-800 hover:text-white ${
                                      playbackRate === rate ? 'font-bold' : 'font-normal'
                                    }`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handlePlaybackRateChange(rate);
                                    }}>
                                    <span>{rate}x</span>
                                    {playbackRate === rate && (
                                      <span className='inline-block'>
                                        <CheckIcon
                                          className={`h-4 w-4 ${
                                            active ? 'text-white' : 'text-teal-800'
                                          }`}
                                          aria-hidden='true'
                                        />
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>,
                        document.body
                      )
                    ) : (
                      <Menu.Items className='absolute right-0 mt-2 w-20 origin-top-right bg-white border border-zinc-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
                        <div className='py-1 shadow-xl'>
                          {PLAYBACK_RATES.map((rate) => (
                            <Menu.Item key={rate}>
                              {({ active }) => (
                                <div
                                  role='button'
                                  className={`flex justify-between items-center cursor-pointer px-4 py-2 text-sm text-gray-700 border-b border-solid border-zinc-200 last:border-none transition-colors duration-150 hover:bg-teal-800 hover:text-white ${
                                    playbackRate === rate ? 'font-bold' : 'font-normal'
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handlePlaybackRateChange(rate);
                                  }}>
                                  <span>{rate}x</span>
                                  {playbackRate === rate && (
                                    <span className='inline-block'>
                                      <CheckIcon
                                        className={`h-4 w-4 ${
                                          active ? 'text-white' : 'text-teal-800'
                                        }`}
                                        aria-hidden='true'
                                      />
                                    </span>
                                  )}
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    )}
                  </Transition>
                </>
              )}
            </Menu>
          </div>
          <input
            type='range'
            min='0'
            max='100'
            value={progress}
            onChange={handleSeek}
            className='w-full'
          />
        </div>
      )}
    </div>
  );
};
