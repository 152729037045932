import { memo } from 'react';

import { DataIcon } from 'components';

function DataDownload({ url, filename }: { url: string; filename: string }) {
  const ext = filename.split('.').pop();

  return (
    <a href={url} download={`${filename}`}>
      <DataIcon ext={ext} />
    </a>
  );
}

DataDownload.displayName = 'DataDownload';

export default memo(DataDownload);
