'use client';
import { Popover, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AntIcon, ChartBuilderEndDate, ChartBuilderStartDate, SimpleLineIcon } from 'components';
import { usePathname } from 'next/navigation';
import { fetchDynamicChart } from 'services/api/fetchDynamicChart';

function getRenderValueAsDate(val) {
  if (val) {
    return new Date(
      new Date(val).toLocaleString('en-US', {
        timeZone: 'UTC',
      })
    );
  }

  return null;
}

function ChartBuilderMenu({ onUpdate }: { onUpdate: (string: any) => void }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    defaultValues: {
      title: '',
      start_date: '',
      end_date: '',
      show_indicator: true,
      show_market: true,
      show_stats: true,
    },
  });

  const maxStartDate = watch('end_date');
  const minEndDate = watch('start_date');
  const pathname = usePathname();
  const filename = pathname.split('/').pop();
  const chartCode = filename.split('.')[0];
  const [error, setError] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (!!searchParams.toString()) {
      const formDataArray = Array.from(searchParams).map(([key, value]) => {
        if (key === 'start_date' || key === 'end_date') {
          return {
            [key]: getRenderValueAsDate(value),
          };
        }
        return {
          [key]: value === 'true' ? true : value === 'false' ? false : value,
        };
      });

      const formData = Object.assign({}, ...formDataArray);

      console.log('populated chart builder form with query data: ', formData);

      reset(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    console.log('data: ', data);
    const formData = {
      ...data,
      start_date: data.start_date ? dayjs(data.start_date).format('YYYY-MM-DD') : '',
      end_date: data.end_date ? dayjs(data.end_date).format('YYYY-MM-DD') : '',
    };

    try {
      const queryParams = new URLSearchParams({
        chart_code: chartCode,
        ...formData,
      });

      const dynamicChartUrl = await fetchDynamicChart(
        `/custom-research/chart?${queryParams.toString()}`
      );

      onUpdate(dynamicChartUrl);

      const urlParams = new URLSearchParams(formData);

      const newUrl = pathname + `?${urlParams.toString()}`;

      // router causes undesirable page refresh--no shallow routing in app router apparently. cool
      // router.push(`?${urlParams.toString()}`);

      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    } catch (error) {
      console.log('error: ', error);
      setError(`Error: ${error.message}` || 'Failed to update chart');
    }
  };

  console.log('errors: ', errors);

  return (
    <Popover className='relative'>
      <Popover.Button className='inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus:ring-teal-500 px-2 py-0.25'>
        <SimpleLineIcon name='settings' className='!text-lg !lg:text-xl  font-normal' />
      </Popover.Button>
      <Transition
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0 translate-y-1'>
        <Popover.Panel className='absolute left-1/2 -translate-x-1/2'>
          {({ close }) => (
            <div className='w-96 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label
                  htmlFor='title'
                  className='block text-sm font-medium text-gray-700 uppercase mb-2'>
                  Chart Title
                </label>
                <input
                  {...register('title')}
                  id='title'
                  placeholder='Chart Title'
                  className='font-normal text-sm text-gray-900 w-full border border-solid border-neutral-200 py-1 px-4 rounded-full mb-8'
                />
                <div className='flex justify-between items-center w-full border-y border-solid border-gray-300 py-6'>
                  <div>
                    <label
                      htmlFor='start_date'
                      className='block text-sm font-medium text-gray-700 uppercase mb-1'>
                      Start Date
                    </label>
                    <ChartBuilderStartDate control={control} maxDate={maxStartDate} />
                    <label
                      htmlFor='end_date'
                      className='block text-sm font-medium text-gray-700 uppercase mb-1 mt-2'>
                      End Date
                    </label>
                    <ChartBuilderEndDate control={control} minDate={minEndDate} />
                    {errors?.end_date && (
                      <p className='text-red-700 text-sm font-medium'>{errors.end_date.message}</p>
                    )}
                  </div>
                  <div className='max-w-48'>
                    <div className='flex items-center mb-2 pb-2 border-b border-solid border-neutral-200'>
                      <input
                        {...register('show_indicator')}
                        id='show_indicator'
                        type='checkbox'
                        className='h-4 w-4 mr-2 bg-transparent rounded-sm border-teal-800 text-teal-800 focus:ring-teal-500 cursor-pointer'
                      />
                      <label
                        htmlFor='show_indicator'
                        className='block text-sm font-medium text-gray-700 uppercase cursor-pointer'>
                        Show Indicator
                      </label>
                    </div>

                    <div className='flex items-center mb-2 pb-2 border-b border-solid border-neutral-200'>
                      <input
                        {...register('show_market')}
                        id='show_market'
                        type='checkbox'
                        className='h-4 w-4 mr-2 bg-transparent rounded-sm border-teal-800 text-teal-800 focus:ring-teal-500 cursor-pointer'
                      />
                      <label
                        htmlFor='show_market'
                        className='block text-sm font-medium text-gray-700 uppercase cursor-pointer'>
                        Show Market
                      </label>
                    </div>

                    <div className='flex items-center'>
                      <input
                        {...register('show_stats')}
                        id='show_stats'
                        type='checkbox'
                        className='h-4 w-4 mr-2 bg-transparent rounded-sm border-teal-800 text-teal-800 focus:ring-teal-500 cursor-pointer'
                      />{' '}
                      <label
                        htmlFor='show_stats'
                        className='block text-sm font-medium text-gray-700 uppercase cursor-pointer'>
                        Show Stats
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  type='submit'
                  disabled={!isValid || isSubmitting}
                  className='btn btn-primary btn-sm mt-4 cursor-pointer w-32 mx-auto'>
                  {isSubmitting ? (
                    <AntIcon name='loading1' className={`!text-white animate-spin `} />
                  ) : (
                    'Submit'
                  )}
                </button>
              </form>
              {error && <p className='text-red-500 text-sm mt-4'>{error}</p>}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

ChartBuilderMenu.displayName = 'ChartBuilderMenu';

export default memo(ChartBuilderMenu);
