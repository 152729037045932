import { memo } from 'react';

import { AntIcon, Button } from 'components';
import { ButtonProps } from 'components/Button/component';

interface FabProps {
  text?: string;
  icon?: string;
  shape?: 'circle' | 'auto';
  superscript?: number | string;
}

type FabWithSprinklesProps = FabProps & ButtonProps;

function FAB({ text, icon, onClick, shape = 'auto', superscript, ...rest }: FabWithSprinklesProps) {
  const circleSize = 48;
  const iconOnlySize = circleSize / 1.75;
  const iconSize = !text ? iconOnlySize : 15;
  const isCircle = shape === 'circle';
  const circleStyles = {
    width: circleSize,
    height: circleSize,
    borderRadius: isCircle ? 999 : undefined,
  };

  const buttonStyles = isCircle
    ? { ...circleStyles }
    : {
        width: '100%',
        borderRadius: 28,
        padding: '12px 30px',
      };
  const textStyles = !!icon ? { marginLeft: 4 } : undefined;

  return (
    <div className='fixed z-[4] bottom-20 mb-8 md:mb-0 right-2 lg:right-10'>
      <Button className='btn-primary' onClick={onClick} style={buttonStyles} {...rest}>
        {icon && (
          <div className='font-normal'>
            <AntIcon name={icon} color='white' size={iconSize} />
          </div>
        )}
        {text && (
          <span className='text-base tracking-wide' style={textStyles}>
            {text}
          </span>
        )}
      </Button>
      {superscript && (
        <div className='absolute right-0 top-[-6px] bg-white flex items-center justify-center h-5 w-5 rounded-full border-2 border-solid border-teal-800'>
          <span className='text-xs font-extrabold'>{superscript}</span>
        </div>
      )}
    </div>
  );
}

export default memo(FAB);
