'use client';

import { categorySlugMap } from '@3fourteen/core';
import { Dialog, Transition } from '@headlessui/react';
import { useMember } from 'hooks/useMember';
import { useSearch } from 'hooks/useSearch';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { PostItem, SearchBar } from 'components';
import Image from 'next/legacy/image';

interface SearchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SearchModal: React.FC<SearchModalProps> = ({ isOpen, onClose }) => {
  const { isPbUser } = useMember();
  const { isLoading, results, onSubmit, query, grouped, isFetching, resetQuery } =
    useSearch('modal');
  const isSearching = results || isLoading;

  const categorized = useMemo(() => {
    return Object.keys(grouped).map((category) => ({
      category,
      posts: grouped[category],
    }));
  }, [grouped]);

  const onSubmitSearch = useCallback(
    (e) => {
      onSubmit(e);
    },
    [onSubmit]
  );

  useEffect(() => {
    if (isOpen) {
      resetQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (isPbUser) return null;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-start justify-center p-4 pt-20'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'>
              <Dialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all'>
                <div className='max-w-screen-sm mx-auto relative'>
                  <div className='w-full'>
                    <SearchBar autoFocus={!isMobile} onSubmit={onSubmitSearch} button='search' />
                  </div>
                  {results && !isFetching && (
                    <p
                      data-testid='search-results-text'
                      className='text-center pt-3 mb-4 font-medium h-10 text-sm'>
                      {`${results.length} results for "${query}"`}
                    </p>
                  )}
                </div>

                {isSearching && (
                  <div className='mt-4 max-h-[60vh] overflow-y-auto'>
                    <Tabs forceRenderTabPanel={true}>
                      <TabList>
                        {results && !isFetching && (
                          <Tab>
                            <span className='text-15'>All</span>
                          </Tab>
                        )}
                        {!isFetching &&
                          categorized.map((group) => (
                            <Tab key={group.category}>
                              <span className='text-15 whitespace-nowrap'>{`${
                                categorySlugMap[group.category]
                              } (${group.posts.length})`}</span>
                            </Tab>
                          ))}
                      </TabList>
                      <div className='w-full max-w-screen-sm mx-auto'>
                        {results && !isFetching ? (
                          <TabPanel>
                            <ul>
                              {results.map((result, index) => (
                                <li
                                  key={index}
                                  className='py-4 border-b border-solid border-neutral-200'>
                                  <PostItem post={result} />
                                </li>
                              ))}
                            </ul>
                          </TabPanel>
                        ) : (
                          <Skeleton count={40} width='100%' />
                        )}
                      </div>
                      {!isFetching &&
                        categorized.map(({ category, posts }) => (
                          <TabPanel key={category}>
                            <div className='w-full max-w-screen-sm mx-auto'>
                              {posts.map((result, index) => (
                                <li
                                  key={index}
                                  className='py-4 border-b border-solid border-neutral-200'>
                                  <PostItem post={result} />
                                </li>
                              ))}
                            </div>
                          </TabPanel>
                        ))}
                    </Tabs>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
