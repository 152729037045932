import { DocumentIcon } from '@heroicons/react/24/outline';
import { memo } from 'react';

function DataIcon({ ext }: { ext: string }) {
  return (
    <div className='flex items-center justify-center relative'>
      <DocumentIcon className='size-5 lg:size-6 text-[#373e41]' />
      <span className='text-[10px] tracking-tighter text-[#373e41] font-black absolute mt-0.5'>
        {ext}
      </span>
    </div>
  );
}

DataIcon.displayName = 'DataIcon';

export default memo(DataIcon);
