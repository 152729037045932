'use client';

import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/20/solid';
import { classNames } from 'helpers/classNames';
import { useMember } from 'hooks/useMember';
import { Fragment, memo, useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { AntIcon, Button, SimpleLineIcon } from 'components';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { navLinks } from 'services/links';
import { routes } from 'services/routes';

function MobileNav() {
  const pathname = usePathname();
  const { isPbUser } = useMember();
  const navItems = navLinks.filter(
    (link) => link.hasOwnProperty('isMobileNavItem') && link.isMobileNavItem
  );
  const isWide = useMedia('(min-width: 1024px)');
  const isSearchPage = pathname.includes(routes.search);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (isPbUser || isWide) return null;

  return (
    <div
      className={classNames(
        'fixed right-2 bg-teal-800 rounded-full h-12 w-12 flex items-center justify-center pb-safe z-[4]',
        isSearchPage ? 'bottom-20' : 'bottom-14'
      )}>
      <Button onClick={() => setOpen(true)}>
        <Bars3Icon className='h-6 w-6 text-white' />
      </Button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-[10]' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                <Dialog.Panel className='w-full relative transform overflow-hidden rounded-lg bg-white text-left transition-all shadow-[0_0_40px_10px_rgba(0,0,0,0.3)]'>
                  <Button onClick={() => setOpen(false)} className='btn btn-sm w-full'>
                    <div className='w-8 h-1 text-center rounded bg-gray-400'></div>
                  </Button>
                  <nav className='grid grid-cols-4 grid-rows-2 rounded z-[4]'>
                    {navItems.map(({ mobileDisplayName, displayName, route, icon, iconFamily }) => {
                      const isActive = pathname === route;
                      const color = isActive ? 'white' : '#254653';

                      return (
                        <div className='basis-1/5' key={displayName}>
                          <Link
                            href={route || '/'}
                            className='flex flex-col items-center justify-center h-full py-4'>
                            <span
                              className={classNames(
                                'h-12 w-12 rounded-full flex items-center justify-center mb-2',
                                isActive
                                  ? 'bg-teal-800 border border-solid border-teal-800'
                                  : 'bg-white border-2 border-solid border-gray-200'
                              )}>
                              {iconFamily === 'ant-design' ? (
                                <AntIcon name={icon} size={20} color={color} />
                              ) : (
                                <SimpleLineIcon name={icon} size={20} color={color} />
                              )}
                            </span>
                            <span
                              className={classNames(
                                'text-xs mt-0.5 md:mt-0 mb-2.5 uppercase font-extrabold block'
                              )}>
                              {mobileDisplayName || displayName}
                            </span>
                          </Link>
                        </div>
                      );
                    })}
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default memo(MobileNav);
