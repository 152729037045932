'use client';

import { Chart, Commentary, CustomPost, Retrospective } from '@3fourteen/core';
import { usePostBy } from 'hooks/usePostBy';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo, useEffect, useMemo } from 'react';

import { LoadingDots, PostViewer, Retro } from 'components';
import Head from 'next/head';
import { trackViewedPost } from 'services/mixpanel';

function PostRenderer({ uri }) {
  const { post } = usePostBy({ uri });

  const pdfKey = useMemo(() => {
    if (post) {
      const isoDate = post?.date.split('T')[0];
      const year = isoDate?.split('-')[0];
      const filename = post?.filename || 'report.pdf';

      return post.categorySlug === 'charts'
        ? filename
        : `${post?.categorySlug}/${year}/${isoDate}/${filename}`;
    } else {
      return undefined;
    }
  }, [post]);

  const { url, error } = useSignedCloudfrontUrl(pdfKey);
  useEffect(() => {
    const isHtml = localStorage.getItem('isHtml');
    const isViewingHtml = isHtml === 'true';

    if (!!post) {
      trackViewedPost(
        post.title,
        post.categorySlug,
        (post as Commentary | Chart).filename,
        isViewingHtml
      );
    }
  }, [post]);

  const HeadTag = useMemo(() => {
    const pageTitle = post?.title;
    return (
      <>
        <Head>
          <title>{`${pageTitle} | 3FR`}</title>
          <meta property='og:title' content={pageTitle} key='title' />
        </Head>
      </>
    );
  }, [post]);

  if (!post) {
    return (
      <div className='h-screen flex items-center justify-center'>
        <LoadingDots color='#999' size='regular' />
      </div>
    );
  }

  if (post?.categorySlug === 'retrospectives') {
    return (
      <>
        {HeadTag}
        <Retro retro={post as Retrospective} />
      </>
    );
  }

  if (post?.categorySlug === 'custom') {
    return (
      <>
        {HeadTag}
        <div dangerouslySetInnerHTML={{ __html: (post as CustomPost)?.content }} />
      </>
    );
  }

  return (
    <section>
      {HeadTag}
      {url && (
        <PostViewer
          url={url}
          filename={(post as Commentary | Chart)?.filename}
          meta={{ post: post as Commentary | Chart }}
        />
      )}
      {error && <p className='text-center mt-[45vh]'>{error}</p>}
    </section>
  );
}

PostRenderer.displayName = 'PostRenderer';

export default memo(PostRenderer);
